import React from "react";
import Header from "../../Components/header/header";
import BannerImage from "../../../src/images/back-living.png";
import BackImage from "../../../src/images/about-shape.png";
import BackInImage from "../../../src/images/shape-1.png";
import BackLineImage from "../../../src/images/bg_circle.png";
import Footer from "../../Components/footer/footer";
import Slider from "react-slick/lib/slider";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useGeolocated } from "react-geolocated";
import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ApiUrl } from "../../utils/common";
import SearchLocationInput from "../../Components/Map/SearchLocationInput";
import Loader from "../../Components/Loader";
import CountUp from 'react-countup';
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import SuperContactForm from "./superContactform";

const SuperLandingPage = () => {
    return (
        <>
            <main id="content">
                <section className="d-flex flex-column" style={{ backgroundImage: `url(${BannerImage})`, backgroundSize: "cover", backgroundPosition: "center" }} >
                    <div className="container-fluids px-10 mt-5 display-re-none ">
                        <div className="d-flex align-items-center pos-relative">
                            <nav className="navbar navbar-expand-xl bg-transparent px-0 w-100 w-xl-auto wdth-100per">
                                <Link className="navbar-brand mr-7 mr-none"
                                    to="#" data-toggle="dropdown" >
                                    <img src="../../images/dpf-logo.png" alt="HomeID" className="normal-logo main-logo-header" />
                                </Link>
                                {/*  <div className="collapse navbar-collapse mt-3 mt-xl-0" id="primaryMenu02">
                                    <ul className="d-r-inline navbar-nav main-menu hover-menu flex-row ml-auto align-items-center justify-content-lg-end flex-wrap  add-positioning-styles">
                                        <li id="navbar-item-home" aria-haspopup="true" aria-expanded="false"
                                            className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-4">
                                            <Link className="nav-link dropdown-toggle p-0 nav-bar-link text-white"
                                                to="#" data-toggle="dropdown" >
                                                Home
                                                <span className="caret"></span>
                                            </Link>
                                        </li>
                                        <li id="navbar-item-listing" aria-haspopup="true" aria-expanded="false"
                                            className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-4">
                                            <a className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                                                href="#about-dpf" data-toggle="dropdown" >
                                                About Us
                                                <span className="caret"></span>
                                            </a>

                                        </li>
                                        <li id="navbar-item-dashboard" aria-haspopup="true" aria-expanded="false"
                                            className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-4">
                                            <a className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                                                href="#our-services" data-toggle="dropdown" >
                                                services
                                                <span className="caret"></span>
                                            </a>
                                        </li>
                                        <li id="navbar-item-pages" aria-haspopup="true" aria-expanded="false"
                                            className="nav-item dropdown py-xl-5 py-2 nav-list-item px-0 px-xl-4">
                                            <a className="nav-link dropdown-toggle p-0 nav-bar-link clr-white-mobile"
                                                href="#contact-us" data-toggle="dropdown" >
                                                Contact
                                                <span className="caret"></span>
                                            </a>
                                        </li>

                                    </ul>
                                </div> */}
                            </nav></div>
                    </div>
                    <div className=" container custom-vh-100 margin-re-top-100 margin-re-top-80" style={{ textAlign: 'center' }} >
                        <div className="row">
                            <div className="col-lg-12 col-md-9 col-sm-4">
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <h2 className="text-black display-2  fs-42 fw-600 text-center" ><img className="landing-fevicon" src="images/favicon-icon.png" /><span className="text-saffron">No.1 Platform for</span> <br /><span className="text-blue">Commercial Real Estate </span> <br />New Development Projects<br /><span className="text-saffron mb-2">COMING SOON</span></h2>
                                    <p className="text-center text-black fs-18 fw-600 fm-nunito">
                                        We connect buyers directly with developers and agents to book their new commercial space
                                    </p>
                                </AnimationOnScroll>
                            </div>
                            <div className="col-lg-12 col-md-9 padding-x-30" style={{ textAlign: 'center' }}>
                                <AnimationOnScroll className="w-100" animateIn="animate__fadeInLeft "  >
                                    <SuperContactForm />
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>

                {/*  <section className="bg-gray-01" >
                    <div className="container-fluid">
                        <div className="z-index-3">
                            <div className=" px-lg-14 py-lg-10">
                                <p className=" text-uppercase text-blue mb-5 fw-600 text-center fs-22">WHO WE ARE</p>
                                <h2 className="text-heading mb-4 fs-20  text-center lh-22 px-6">Dollar Per Foot is redefining the Commercial Real Estate landscape by offering first of its kind Commercial Real Estate Platform for
                                    Developers, Agents and other Businesses.</h2>
                                <p className="text-center text-black fs-16 fm-nunito">
                                    With proven track record of 15+ years in Commercial Real Estate, our founding partners have been a Developer in commercial  projects, real estate investor and CEO of a Property Management Company. Dollar Per Foot offers commercial new development projects investment opportunities for Buyers and for Developers to showcase their new commercial developments and to Agents to list their exclusive commercial properties on our platform.
                                </p>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/*  <section id="our-services">
                    <div className="container-fluid">
                        <div className=" px-lg-14 py-lg-10">
                            <h2 className="text-dark lh-1625 text-center mb-2 fs-22 fs-md-32">Our services</h2>
                            <p className=" text-black fs-16 text-center fm-nunito">We are the only platform for Commercial New Development Projects in Canada & USA. Developers, agents, and other Professionals can list their properties that focus just on  commercial new developments and can have a targeted reach out to the interested buyers.</p>
                            <AnimationOnScroll animateIn="animate__fadeInUp">
                                <div className="row mt-8">
                                    <div className="col-md-3 mb-6 mb-lg-0">
                                        <div className="card shadow-2 px-3 py-3 h-100 border-0">
                                            <div className="card-img-top d-flex align-items-end justify-content-center">
                                                <span className="text-primary fs-90 lh-1"><img src="images/dev.png" alt="otherbusiness" /></span>
                                            </div>
                                            <div className="card-body px-0 pt-6 pb-0 ">
                                                <h4 className="card-title fs-18 lh-17 text-center text-dark mb-2">Developers</h4>
                                              
                                                <ul>
                                                    <li>List New & Upcoming Commercial Projects</li>
                                                    <li>Get matched buyers </li>
                                                    <li>Get targeted leads</li>
                                                    <li>Showcase your team and exclusive agents</li>
                                                    <li>Showcase Developer Profile to the audience</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-6 mb-lg-0">
                                        <div className="card shadow-2 px-3 py-3 h-100 border-0">
                                            <div className="card-img-top d-flex align-items-end justify-content-center">
                                                <span className="text-primary fs-90 lh-1"><img src="images/agent.png" alt="otherbusiness" /></span>
                                            </div>
                                            <div className="card-body px-0 pt-6 pb-0 ">
                                                <h4 className="card-title fs-18 lh-17 text-center text-dark mb-2">Agents</h4>
                                              
                                                <ul>
                                                    <li>List Commercial Projects</li>
                                                    <li>Showcase your Service Portfolio </li>
                                                    <li>Get matched buyers </li>
                                                    <li>Get targeted leads</li>
                                                    <li>Be the first to get notified on new commercial projects</li>
                                                </ul>
                                            </div>
                                        </div></div>
                                    <div className="col-md-3 mb-6 mb-lg-0">
                                        <div className="card shadow-2 px-3 py-3 h-100 border-0">
                                            <div className="card-img-top d-flex align-items-end justify-content-center">
                                                <span className="text-primary fs-90 lh-1"><img src="images/ob.png" alt="otherbusiness" /></span>
                                            </div>
                                            <div className="card-body px-0 pt-6 pb-0 ">
                                                <h4 className="card-title fs-18 lh-17 text-center text-dark mb-2">Professionals</h4>
                                              
                                                <ul>
                                                    <li>Showcase your Service Portfolio</li>
                                                    <li>Get matched buyers </li>
                                                    <li>Get targeted leads</li>
                                                    <li>Increase your reach out on Dollar Per Foot</li>
                                                    <li>Be the first to get notified on new commercial projects</li>
                                                </ul>
                                            </div>
                                        </div></div>
                                    <div className="col-md-3 mb-6 mb-lg-0">
                                        <div className="card shadow-2 px-3 py-3 h-100 border-0">
                                            <div className="card-img-top d-flex align-items-end justify-content-center">
                                                <span className="text-primary fs-90 lh-1"><img src="images/buyers.png" alt="otherbusiness" /></span>
                                            </div>
                                            <div className="card-body px-0 pt-6 pb-0 ">
                                                <h4 className="card-title fs-18 lh-17 text-center text-dark mb-2">Buyers</h4>
                                              
                                                <ul>
                                                    <li>Be the first to get notified on new commercial projects</li>
                                                    <li>Never miss an upcoming commercial opportunity</li>
                                                    <li>Maximize your return by booking at the right time at the right price through Dollar Per Foot</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </section> */}
                {/*  <section className="">
         
          <div id="contact-us">
          <iframe className="footer-newsletter" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.3303833182376!2d-79.69736643370501!3d43.724463095871045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3c354cf29489%3A0x89e936be5b5481b7!2s10-8550%20Torbram%20Rd%20%23288%2C%20Brampton%2C%20ON%20L6T%200H7%2C%20Canada!5e0!3m2!1sen!2sin!4v1669028135458!5m2!1sen!2sin"   height="400px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </section> */}
            </main>
            {/* <footer className="bg-dark text-muted">
                <div className="container container-xxl">
                <div className="py-2 row ">
                        <ul className="list-inline mb-0 col-md-6 mr-auto">
                            <li className="list-inline-item mr-6">
                                <a href="#" className="text-muted lh-26 font-weight-500 hover-white">Terms of Use</a>
                            </li>
                            <li className="list-inline-item">
                                <a href="#" className="text-muted lh-26 font-weight-500 hover-white">Privacy Policy</a>
                            </li>
                        </ul>
                        <p className=" list-inline col-md-auto mb-0 text-muted">
                            © 2022 DollarPerFoot. All Rights Reserved
                        </p>
                    </div>
                </div>
            </footer> */}
        </>
    )
}
export default SuperLandingPage;